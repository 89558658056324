const { isSpanishPage } = require("./src/system/lang/isSpanishPage");

// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname);

  const localStorageLang =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("lang")
      : "en_GB";

  if (isSpanishPage() && localStorageLang !== "es_ES")
    localStorage.setItem("lang", "es_ES");

  if (!isSpanishPage() && localStorageLang !== "en_GB")
    localStorage.setItem("lang", "en_GB");

  console.log("old pathname", prevLocation ? prevLocation.pathname : null);
};
