exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-es-aplicaciones-para-medicina-casos-de-uso-js": () => import("./../../../src/pages/es/aplicaciones-para-medicina/casos-de-uso.js" /* webpackChunkName: "component---src-pages-es-aplicaciones-para-medicina-casos-de-uso-js" */),
  "component---src-pages-es-aplicaciones-para-medicina-pasos-para-usar-medoms-js": () => import("./../../../src/pages/es/aplicaciones-para-medicina/pasos-para-usar-medoms.js" /* webpackChunkName: "component---src-pages-es-aplicaciones-para-medicina-pasos-para-usar-medoms-js" */),
  "component---src-pages-es-aplicaciones-para-medicina-razones-para-elegir-medoms-js": () => import("./../../../src/pages/es/aplicaciones-para-medicina/razones-para-elegir-medoms.js" /* webpackChunkName: "component---src-pages-es-aplicaciones-para-medicina-razones-para-elegir-medoms-js" */),
  "component---src-pages-es-como-usar-medoms-contribuidor-js": () => import("./../../../src/pages/es/como-usar-medoms/contribuidor.js" /* webpackChunkName: "component---src-pages-es-como-usar-medoms-contribuidor-js" */),
  "component---src-pages-es-como-usar-medoms-desarrollador-js": () => import("./../../../src/pages/es/como-usar-medoms/desarrollador.js" /* webpackChunkName: "component---src-pages-es-como-usar-medoms-desarrollador-js" */),
  "component---src-pages-es-como-usar-medoms-necesito-una-aplicacion-js": () => import("./../../../src/pages/es/como-usar-medoms/necesito-una-aplicacion.js" /* webpackChunkName: "component---src-pages-es-como-usar-medoms-necesito-una-aplicacion-js" */),
  "component---src-pages-es-contactar-js": () => import("./../../../src/pages/es/contactar.js" /* webpackChunkName: "component---src-pages-es-contactar-js" */),
  "component---src-pages-es-framework-bussiness-objects-compartidos-js": () => import("./../../../src/pages/es/framework/bussiness-objects-compartidos.js" /* webpackChunkName: "component---src-pages-es-framework-bussiness-objects-compartidos-js" */),
  "component---src-pages-es-framework-enfoque-sin-codigo-js": () => import("./../../../src/pages/es/framework/enfoque-sin-codigo.js" /* webpackChunkName: "component---src-pages-es-framework-enfoque-sin-codigo-js" */),
  "component---src-pages-es-framework-formularios-con-contexto-multidimensional-js": () => import("./../../../src/pages/es/framework/formularios-con-contexto-multidimensional.js" /* webpackChunkName: "component---src-pages-es-framework-formularios-con-contexto-multidimensional-js" */),
  "component---src-pages-es-framework-tecnologias-usadas-js": () => import("./../../../src/pages/es/framework/tecnologias-usadas.js" /* webpackChunkName: "component---src-pages-es-framework-tecnologias-usadas-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-legal-politica-de-privacidad-js": () => import("./../../../src/pages/es/legal/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-es-legal-politica-de-privacidad-js" */),
  "component---src-pages-es-legal-terminos-de-uso-js": () => import("./../../../src/pages/es/legal/terminos-de-uso.js" /* webpackChunkName: "component---src-pages-es-legal-terminos-de-uso-js" */),
  "component---src-pages-framework-multidimensional-contexted-forms-js": () => import("./../../../src/pages/framework/multidimensional-contexted-forms.js" /* webpackChunkName: "component---src-pages-framework-multidimensional-contexted-forms-js" */),
  "component---src-pages-framework-no-code-approach-js": () => import("./../../../src/pages/framework/no-code-approach.js" /* webpackChunkName: "component---src-pages-framework-no-code-approach-js" */),
  "component---src-pages-framework-shared-bussiness-objects-js": () => import("./../../../src/pages/framework/shared-bussiness-objects.js" /* webpackChunkName: "component---src-pages-framework-shared-bussiness-objects-js" */),
  "component---src-pages-framework-tech-stack-js": () => import("./../../../src/pages/framework/tech-stack.js" /* webpackChunkName: "component---src-pages-framework-tech-stack-js" */),
  "component---src-pages-healthcare-apps-adoption-steps-js": () => import("./../../../src/pages/healthcare-apps/adoption-steps.js" /* webpackChunkName: "component---src-pages-healthcare-apps-adoption-steps-js" */),
  "component---src-pages-healthcare-apps-reasons-to-choose-medoms-js": () => import("./../../../src/pages/healthcare-apps/reasons-to-choose-medoms.js" /* webpackChunkName: "component---src-pages-healthcare-apps-reasons-to-choose-medoms-js" */),
  "component---src-pages-healthcare-apps-use-cases-js": () => import("./../../../src/pages/healthcare-apps/use-cases.js" /* webpackChunkName: "component---src-pages-healthcare-apps-use-cases-js" */),
  "component---src-pages-how-to-use-medoms-contributor-js": () => import("./../../../src/pages/how-to-use-medoms/contributor.js" /* webpackChunkName: "component---src-pages-how-to-use-medoms-contributor-js" */),
  "component---src-pages-how-to-use-medoms-developer-js": () => import("./../../../src/pages/how-to-use-medoms/developer.js" /* webpackChunkName: "component---src-pages-how-to-use-medoms-developer-js" */),
  "component---src-pages-how-to-use-medoms-get-an-app-js": () => import("./../../../src/pages/how-to-use-medoms/get-an-app.js" /* webpackChunkName: "component---src-pages-how-to-use-medoms-get-an-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */)
}

